<script>
import Aws from '@/services/Aws';
import {minLength, maxLength, required} from "vuelidate/lib/validators";
import validationMessages from '@/components/validations'


export default {
  components:{
    validationMessages
  },
  props: ['dbData', 'clusterName'],
  data() {
    return {
      csrf_token: localStorage.getItem('csrf_token'),
      submitted: false,
      showModal: false,
      tryingToEdit: false,
      showLoader:false,
      modalTitle:'',
      DBInstanceIdentifier: '',
      DBInstanceClass: '',
      Engine: '',
      PubliclyAccessible: '',
      new_DBInstanceIdentifier: '',
      selectedInstance: [],
      classTypes:[],
      classType:'',
    };
  },
  validations: {
    DBInstanceIdentifier: {
      required,
    },
    new_DBInstanceIdentifier:{
      required,
      minLength: minLength(3),
      maxLength: maxLength(30),
    },
    classType:{
      required
    }
  },
  methods: {

    setWriterInstanceAsDefault() {
      // Find the instance with the InstanceRole 'writer'
      const writerInstance = this.dbData.find(
              (instance) => instance.InstanceRole === 'writer'
      );
      // If found, set the DBInstanceIdentifier to the writer's identifier
      if (writerInstance) {
        this.DBInstanceIdentifier = writerInstance.DBInstanceIdentifier
        this.DBInstanceClass = writerInstance.DBInstanceClass
        this.Engine = writerInstance.Engine
        this.PubliclyAccessible = writerInstance.PubliclyAccessible
      }
    },

    async createReadDBInstance(){
      this.tryingToEdit = true;
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.tryingToEdit = false;
        return;
      } else {
        try {
          await Aws.createReadDBInstance({
            DBInstanceIdentifier: this.DBInstanceIdentifier,
            new_DBInstanceIdentifier: this.new_DBInstanceIdentifier,
            DBClusterIdentifier: this.clusterName,
            classType: this.classType,
            csrf_token: this.csrf_token
          }).then((response) => {
            this.successmsg("New read instance has been created");
            this.closeModal();
          }).catch(error => {
            this.error = error.response.data.error ? error.response.data.error : "";
            this.failedmsg(this.error);
          }).finally(() => {
            this.refreshData();
            this.tryingToEdit = false
          });
        } catch (error) {
          this.error = error.response.data.error ? error.response.data.error : "";
          this.failedmsg(this.error)
        }
      }

      this.tryingToEdit = false
    },

    onInstanceChange(selectedIdentifier) {
      // Find the full object for the selected DBInstanceIdentifier
      const selectedInstance = this.dbData.find(
              (instance) => instance.DBInstanceIdentifier === selectedIdentifier
      );
      // Update selectedInstance with the whole object
      this.DBInstanceClass = selectedInstance.DBInstanceClass
      this.Engine = selectedInstance.Engine
      this.PubliclyAccessible = selectedInstance.PubliclyAccessible
    },

    getRDSClassTypes(){
      Aws.getRDSClassTypes().then((response) => {
        if(response.data.data){
          let classes = response.data.data;
          this.classTypes = classes.map(c => c.class_name)

          if (!this.classTypes.includes(this.DBInstanceClass)) {
            this.classTypes.push(this.DBInstanceClass);
          }

          this.classType = this.DBInstanceClass
        }
      }).catch((error) => {
        this.error = error.response.data.error
                ? error.response.data.error
                : "";
      }).finally(() => {

      });
    },

    refreshData() {
      this.$emit('onRefresh')
    },

    closeModal() {
      this.showModal = false
    },

    initialData(){
      this.setWriterInstanceAsDefault()
      this.getRDSClassTypes()
    }

  }
};
</script>

<template>
  <b-modal @shown="initialData" v-model="showModal" id="createReadDBInstance_edit" title="Create Read DB Instance" title-class="font-18" centered>
    <form @submit.prevent="createReadDBInstance">
      <div class="row">
        <div class="col-6">
          <p class="font-size-16 mb-2"><span class="text-strong">Cluster Name:</span> {{clusterName}}</p>

        </div>
        <div class="col-6">
          <p class="font-size-16 mb-2"><span class="text-strong">Engine:</span> {{Engine}}</p>
          <p class="font-size-16 mb-2"><span class="text-strong">Publicly Accessible:</span> {{PubliclyAccessible}}</p>

        </div>
        <div class="col-sm-12 col-md-12">
          <label class="control-label form-label">Choose Instance to Replicate</label>
          <b-form-select
                  class="form-control"
                  aria-describedby="Eligible-dbinstances"
                  v-model="DBInstanceIdentifier"
                  @change="onInstanceChange"
                  :class="{
                      'is-invalid': submitted && $v.DBInstanceIdentifier.$error,
                    }"
          >
            <option v-for="instance in dbData" :key="instance.DBInstanceIdentifier" :value="instance.DBInstanceIdentifier">
              {{ instance.DBInstanceIdentifier }}
            </option>
          </b-form-select>
          <validationMessages v-if="submitted" :fieldName="'Replicate Instance'" :validationName="$v.DBInstanceIdentifier"></validationMessages>
        </div>

        <div class="col-sm-12 col-md-12">
          <label class="control-label form-label">Choose Instance Class Type</label>

          <b-form-select
                  class="form-control"
                  aria-describedby="instanceClassType"
                  v-model="classType"
                  :class="{
                      'is-invalid': submitted && $v.classType.$error,
                    }"
          >
            <option v-for="type in classTypes" :key="type" :value="type ">
              {{ type }}
            </option>
          </b-form-select>
          <validationMessages v-if="submitted" :fieldName="'Class Type'" :validationName="$v.classType"></validationMessages>
        </div>

        <div class="col-sm-12 col-md-12">
          <div class="form-group">
            <label for="new_DBInstanceIdentifier">DB Instance Name:</label>
            <input type="text" id="new_DBInstanceIdentifier"
                   class="form-control"
                   v-model="new_DBInstanceIdentifier"
                   :class="{
                      'is-invalid': submitted && $v.new_DBInstanceIdentifier.$error,
                    }"
            />
            <validationMessages v-if="submitted" :fieldName="'DB Instance Name'" :validationName="$v.new_DBInstanceIdentifier"></validationMessages>
          </div>
        </div>

      </div>

    </form>
    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="createReadDBInstance" :disabled="tryingToEdit">
        <b-spinner v-show="tryingToEdit" small></b-spinner>
        Create</b-button>
    </template>
  </b-modal>
</template>